<template>
  <div>
    <van-skeleton v-if="loading.content" title :row="3" class="mt-4" />
    <div v-if="!error_state && !loading.content">
      <!-- <van-image fit="contain" :src="banner[0]" class="p-2 no-border" /> -->
      <van-swipe indicator-color="#182953" :autoplay="3000">
        <van-swipe-item v-for="(image, index) in banner" :key="index">
          <van-image fit="contain" :src="image" class="p-2 no-border" />
        </van-swipe-item>
      </van-swipe>
      <div class="van-ellipsis--l2 p-2" v-if="description" style="white-space: pre-wrap;" v-html="description">
      </div>
      <van-cell-group>
        <van-form @submit="onSubmit" class="mb-4">
          <span v-for="(item, index) in fields"
            :key="index">
            <van-divider v-if="item.show_on_condition ? item.show_on_condition && form[item.show_on_condition.field] === item.show_on_condition.value : true"/>
            <span v-if="item.show_on_condition ? item.show_on_condition && form[item.show_on_condition.field] === item.show_on_condition.value : true" class="van-field__label ml-4">{{item.name}}</span>
            <van-field
              label-width="130"
              v-model="form[item.field_name + (['picker', 'picker_api', 'timepicker_api'].includes(item.type) ? '_str' : '')]"
              :placeholder="item.placeholder"
              :type="item.type"
              :maxlength="['picker', 'datepicker', 'picker_api', 'timepicker_api', 'timepicker'].includes(item.type) ? null : item.limit"
              show-word-limit
              v-if="item.show_on_condition ? item.show_on_condition && form[item.show_on_condition.field] === item.show_on_condition.value : true"
              :is-link="['picker', 'datepicker', 'picker_api', 'timepicker_api', 'timepicker'].includes(item.type)"
              :readonly="['picker', 'datepicker', 'picker_api', 'timepicker_api', 'timepicker'].includes(item.type)"
              @click="handlerClickField(item.type, item.field_name)"
              :rules="[
                { required: item.is_required, message: $t('error_msg.required_field') },
                { validator: item.type == 'email' ? emailValidator : () => {}, message: 'Invalid email format' },
              ]">
              <template v-if="item.type == 'select'" #input>
                <van-radio-group v-model="form[item.field_name]">
                  <van-radio
                    checked-color="#182953"
                    class="mt-2" v-for="(val, index) in item.selection_list" :name="val.value" :key="index">{{ val.name }}</van-radio>
                </van-radio-group>
              </template>
              <template v-else-if="item.type == 'switch'" #input>
                {{ $t(item.options.negative_text) }}&nbsp;<van-switch v-model="form[item.field_name]" active-color="#182953" :active-value="'true'" :inactive-value="'false'" />&nbsp;{{ $t(item.options.positive_text) }}
              </template>
            </van-field>
            <van-popup v-if="item.type == 'datepicker'" v-model="bool_status[item.field_name]" position="bottom">
              <van-datetime-picker
                type="date"
                :title="$t('choose_date')"
                :confirm-button-text="$t('general.confirm')"
                :cancel-button-text="$t('general.cancel')"
                :min-date="item.minDate"
                :max-date="item.maxDate"
                v-model="item.defaultDatePickerValue"
                :columns-order="['year', 'month', 'day']"
                @change="pickerChangeHandler($event, item.field_name, item.type)"
                @confirm="pickerHandler($event, item.field_name, item.type)"
              />
            </van-popup>
            <van-popup v-if="item.type == 'picker' || item.type == 'timepicker'" v-model="bool_status[item.field_name]" position="bottom">
              <van-picker
                :title="item.name"
                show-toolbar
                :confirm-button-text="$t('general.confirm')"
                :cancel-button-text="$t('general.cancel')"
                :columns="orderBy(item.selection_list_str.map((v) => v.label))"
                @cancel="bool_status[item.field_name] = false"
                @confirm="pickerHandler($event, item.field_name, item.type)"
              />
            </van-popup>
            <van-popup v-if="['picker_api', 'timepicker_api'].includes(item.type)" v-model="bool_status[item.field_name]" position="bottom">
              <van-picker
                :title="item.name"
                show-toolbar
                :confirm-button-text="$t('general.confirm')"
                :cancel-button-text="$t('general.cancel')"
                :loading="loading_state[item.field_name]"
                :columns="picker_data_list[item.field_name]"
                @cancel="pickerCancel($event, item.field_name)"
                @confirm="pickerHandler($event, item.field_name, item.type)"
              />
            </van-popup>
          </span>
          <div class="floating-cta mt-4">
            <van-button class="w-full main-cta" type="brand">{{ $t('general.submit') }}</van-button>
          </div>
        </van-form>
      </van-cell-group>
    </div>
    <div v-else-if="error_state">
      <van-empty image="error" :description="error_message" />
    </div>
    <van-dialog v-model="modal.shown" :title="modal.title" :show-cancel-button="false" confirm-button-text="Oke"
      confirm-button-color="#182953" :beforeClose="onCloseModal">
      <template #title>
        <div style="display: flex; align-items: center; justify-content: center; ">
          <van-icon v-if="!modal.is_error" color="#2fb344" name="certificate" size="20" />
          <van-icon v-if="modal.is_error" color="#cd201f" name="close" size="20" />
          <span class="ml-2">{{ modal.title }}</span>
        </div>
      </template>
      <p class="p-4 text-center">{{ modal.message }}</p>
    </van-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import { GET_FORM, SUBMIT_FORM, DYNAMIC_PICKER_LIST } from '../../store/modules/form';
import {
  orderBy,
  flattenDeep,
} from 'lodash';

export default {
  metaInfo: {
    title: 'Makna.ai',
  },
  data() {
    return {
      orderBy,
      radio: '',
      banner: [],
      loading: {
        content: true,
      },
      loading_state: {},
      picker_data_list: {},
      defaultDatePickerValue: new Date(1970, 7, 25),
      bannerMudik: '',
      showPickerDestination: false,
      form: {},
      minDate: new Date(1950, 0, 1),
      maxDate: new Date(),
      fields: [],
      error_state: false,
      error_message: '',
      modal: {
        title: 'Yay berhasil!',
        shown: false,
        is_error: false,
        message: '',
      },
      redirect_url: 'https://makna.co.id',
      form_type: '',
      detail_product: {},
      description: '',
      bool_status: {},
    };
  },
  mounted() {
    this.validateCampaign();
  },
  methods: {
    emailValidator(value) {
      const email_regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      return email_regex.test(value);
    },
    async onSubmit() {
      const loading_toast = this.$toast.loading('Sending...');
      await this.$store.dispatch(SUBMIT_FORM, {
        id: this.$route.params.id,
        data: this.form,
      }).then(() => {
        loading_toast.clear();
        this.modal = {
          title: this.$t('general.success'),
          shown: true,
          is_error: false,
          message: this.$t('general.success_message'),
        };
        Object.keys(this.form, (key) => {
          this.$set(this.form, key, '');
        });
      }).catch(() => {
        loading_toast.clear();
        this.modal = {
          title: 'Oops',
          shown: true,
          is_error: true,
          message: this.$t('response_error.general_error'),
        };
      });
    },
    checkMinDate(rules) {
      if (!rules) {
        return new Date(1950, 0, 1);
      }
      return new Date();
    },
    checkMaxDate(rules) {
      if (!rules) {
        return new Date();
      }
      return new Date();
    },
    onCloseModal(action, done) {
      if (!this.modal.is_error) window.location.href = this.redirect_url;
      done();
    },
    onConfirm(value) {
      this.form.destination = value;
      this.showPickerDestination = false;
    },
    async validateCampaign() {
      await this.$store.dispatch(GET_FORM, {
        id: this.$route.params.id,
      }).then((response) => {
        this.$root.$i18n.locale = response.language;
        this.fields = response.fields.map((v) => {
          v.name = this.$t(v.field_name);
          v.placeholder = this.$t('required_fields');
          if (['select'].includes(v.type)) {
            v.selection_list = v.selection_list.map((s) => ({ value: s, name: this.$t(s) }));
          }
          if (['picker', 'datepicker', 'timepicker'].includes(v.type)) {
            this.$set(this.bool_status, v.field_name, false);
            if (v.selection_list) {
              v.selection_list_str = v.selection_list.map((v) => ({ label: this.$t(v), value: v }));
            }
            if (v.type === 'timepicker') {
              let start = '00:00';
              let end = '23:00';
              if (v.picker_range) {
                start = v.picker_range[0];
                end = v.picker_range[1];
                const diff = moment(end, 'HH:mm').diff(moment(start, 'HH:mm'), 'hour');
                v.selection_list_str = [];
                for (let i = 0; i <= diff; i += 1) {
                  const val = moment(start, 'HH:mm').add(i, 'hour').format('HH:mm');
                  v.selection_list_str.push({
                    label: val,
                    value: val,
                  });
                }
              }
            }
            if (v.type === 'datepicker') {
              v.minDate = new Date(1900, 0, 1);
              v.maxDate = new Date();
              if (v.limit && v.limit_type) {
                v.maxDate = moment().add(v.limit, v.limit_type).toDate();
                v.minDate = new Date();
              }
              v.defaultDatePickerValue = new Date();
            }
          }
          if (['picker_api', 'timepicker_api'].includes(v.type)) {
            this.$set(this.bool_status, v.field_name, false);
            this.$set(this.picker_data_list, v.field_name, [{ text: this.$t('no_data'), disabled: true }]);
            this.$set(this.loading_state, v.field_name, false);
          }
          return v;
        });
        this.form = {};
        response.fields.forEach((item) => {
          let default_value = '';
          if (item.type === 'switch') {
            default_value = 'no';
          }
          if (item.type === 'picker') {
            this.$set(this.form, `${item.field_name}_str`, '');
          }
          this.$set(this.form, item.field_name, default_value);
        });
        this.description = response.description;
        this.banner = response.banner_image;
        if (response.redirect_url) this.redirect_url = response.redirect_url;
        this.form_type = response.type;
        this.detail_product = response.detail_product;
        this.$parent.$data.navbarTitle = this.$t(`form.title.${this.form_type}`);
        this.error_state = false;
      }).catch((err) => {
        const { response } = err;
        console.log(err);
        if (response?.data?.error?.code !== 500) {
          this.error_message = response.data?.error?.message;
        }
        this.$parent.$data.navbarTitle = 'Page not Found';
        this.error_state = true;
      });
      this.loading.content = false;
    },
    async handlerClickField(field_type, field_name) {
      if (['picker', 'datepicker', 'picker_api', 'timepicker_api', 'timepicker'].includes(field_type)) {
        if (field_type === 'picker_api' || field_type === 'timepicker_api') {
          // TODO - Hit api
          const field = this.fields.find((v) => v.field_name === field_name);
          // console.log(field_name, field);
          const query = {};
          Object.keys(field.api_query).forEach((val) => {
            const o = field.api_query[val];
            const k = o.value;
            if (o.type === 'field') {
              query[val] = this.form[k];
            } else if (o.type === 'date_index') {
              const val_date_index = moment(this.form[k], 'DD-MM-YYYY').day() - 1;
              query[val] = val_date_index;
            }
            // console.log(k, this.form[k]);
          });
          // console.log(query);
          let parent_id = null;
          if (field.api_params?.parent_id) {
            const k = field.api_params?.parent_id;
            parent_id = this.form[k.value];
          }
          this.$set(this.picker_data_list, field_name, [{ text: this.$t('no_data'), disabled: true }]);
          const response = await this.$store.dispatch(DYNAMIC_PICKER_LIST, {
            id: this.$route.params.id,
            parent_id,
            query,
          });
          if (response.values.length) {
            let mapped_value = response.values.map((v) => ({ text: v.value_str, value: v._id, obj_value: v._id }));
            if (field_type === 'timepicker_api') {
              // console.log(response.values);
              mapped_value = [];
              if (!response.values[0].value_str) {
                mapped_value = [{ text: this.$t('no_data'), disabled: true }];
              } else {
                // console.log(response.values[0].value_str.split('#').map((v) => v.split('-')));
                const arr_mapped_value = flattenDeep(response.values[0].value_str.split('#').map((v) => v.split('-')));
                // console.log(arr_mapped_value);
                const start = arr_mapped_value[0];
                const end = arr_mapped_value[arr_mapped_value.length - 1];
                const diff = Math.abs(moment(start, 'HH').diff(moment(end, 'HH'), 'hour'));
                for (let i = 0; i < diff; i += 1) {
                  mapped_value.push({
                    text: moment(start, 'HH:mm').add(i, 'hour').format('HH:00'),
                  });
                }
              }
            }
            this.$set(this.picker_data_list, field_name, mapped_value);
          }
        }
        this.bool_status[field_name] = !this.bool_status[field_name];
      }
    },
    pickerHandler(value, field_name, field_type) {
      try {
        this.form[field_name] = value;
        if (field_type === 'datepicker') {
          this.form[field_name] = moment(value).format('DD-MM-YYYY');
          this.fields.filter((v) => v.enable_on_condition?.field === field_name).forEach((val) => {
            this.form[val.field_name] = null;
            this.form[`${val.field_name}_str`] = null;
          });
        }

        if (['picker', 'picker_api', 'timepicker_api'].includes(field_type)) {
          // Reset depend form
          this.fields.filter((v) => v.enable_on_condition?.field === field_name).forEach((val) => {
            this.form[val.field_name] = null;
            this.form[`${val.field_name}_str`] = null;
          });

          this.form[`${field_name}_str`] = typeof value === 'object' ? value.text : value;
          const fields = this.fields.find((v) => v.type === field_type);
          let obj = {};
          if (field_type === 'picker') {
            obj = fields.selection_list_str.find((v) => v.label === value);
          }
          if (field_type === 'picker_api' || field_type === 'timepicker_api') {
            obj = this.picker_data_list[field_name].find((v) => v.obj_value === value.obj_value);
          }
          // console.log(obj);
          this.form[field_name] = typeof obj?.value === 'object' ? obj.value.text : obj.value;
        }
        this.$set(this.bool_status, field_name, false);
      } catch (error) {
        console.log(error);
      }
    },
    pickerCancel(component, field_name) {
      this.$set(this.bool_status, field_name, false);
    },
    pickerChangeHandler(component, field_name, field_type) {
      const value = component.getValues().join('-');
      if (field_type === 'datepicker') {
        this.form[field_name] = value;
      }
    },
  },
};
</script>
<style>
.btn-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.v-form-title {
  margin: 0;
  padding: 32px 16px 16px;
  /* color: rgba(69, 90, 100, 0.6); */
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.floating-cta {
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0px -4px 4px 0px #E2E2EE;
  position: fixed;
  padding: 12px;
  bottom: 0;
  left: 0;
  width: 100%;
}
.main-cta {
  padding: 12px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.37px;
  background-color: #182953;
  border-radius: 8px;
  display: block;
  color: #ffffff;
  font-weight: 600;
}
</style>